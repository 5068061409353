import {
  apiUserAiduoDel,
  apiPracticeUnit,
  apiPracticeJob,
  apiPracticeRecord,
  apiAddexericise
} from '@/api/api.js'
import AudioTool from '@/components/AudioTool/AudioTool.vue'
import Recorder from '@/components/Recorder/Recorder.vue'
import VueVideo from '@/components/vue-video.vue'
import * as upload from '@/utils/upload'

export default {
  components: {
    AudioTool,
    Recorder,
    VueVideo
  },
  data() {
    return {
      resultData: [],
      practice_id: sessionStorage.getItem('practice_id'),
      caqid: sessionStorage.getItem('caqid'),
      PccourseDetailTitle: sessionStorage.getItem('tcl_pccourse-detail_title'),
      userPractice: '',
      Delisible: false,
      del_item: '',
      del_index: '',
      del_pindex: '',
      answerVisible: false,
      page: 1,
      user_practice_unit: [],
      assianStatus: 0,

      isRecorder: false,
      recoredUrl: null
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getUcld()
    },
    getUcld() {
      let paramsBase = {
        id: this.practice_id
      }
      apiPracticeUnit(paramsBase).then((res) => {
        this.resultData = res.data.detail
        for (let i in this.resultData.inClass) {
          this.resultData[i] = this.resultData.inClass[i]
        }
        for (let j in this.resultData.sample) {
          this.resultData.sample = this.resultData.sample[j]
        }
        for (let k in this.resultData.rule) {
          this.resultData.rule = this.resultData.rule[k]
        }
        this.type = this.resultData.type
        this.getAssignment()
        //this.resultData.next_course = Object.values(this.resultData.next_course)
      })
    },

    getAssignment() {
      let paramsBase = {
        id: this.practice_id,
        page: this.page,
        type: this.type
      }
      apiPracticeJob(paramsBase).then((res) => {
        this.user_practice_unit = res.data.list
      })
    },
    getPractice() {
      let paramsBase = {
        id: this.practice_id,
        page: this.page,
        type: this.type
      }
      apiPracticeRecord(paramsBase).then((res) => {
        this.user_practice_unit = res.data.list
      })
    },
    load() {
      this.page++
      this.getSkillData()
    },
    handleStartRecorder() {
      this.recoredUrl = null
    },
    getMsgFormSon(data) {
      const name = this.getMath() + this.getMath() + this.getMath() + '.wav'
      upload.file({
        file: data,
        fileName: name,
        onSuccess: ({ data }) => {
          this.isRecorder = true

          this.user_practice_unit = [
            {
              time: 'now',
              audio: data.oss_url
            }
          ]
          this.recoredUrl = data.oss_url
        }
      })
    },
    //生成随机数
    getMath() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    },
    submitPrac() {
      if (this.resultData.is_answered == 1) {
        this.$layer.msg('您已经完成此作业，请勿重复提交')
        return false
      }
      let paramsBase = {
        id: this.practice_id,
        contents: this.recoredUrl,
        caqid: this.caqid
      }
      apiAddexericise(paramsBase).then((res) => {
        // this.resultData.user_practice_unit.push({
        //     content: urlValue,
        //     id: res.data.puid,
        // })
        this.userPractice = ''
        this.init()
      })
    },
    submit() {
      if (this.resultData.is_answered == 1) {
        this.$layer.msg('您已经完成此作业，请勿重复提交')
        return false
      }
      let paramsBase = {
        id: this.practice_id,
        contents: this.userPractice,
        caqid: this.caqid
      }
      apiAddexericise(paramsBase).then((res) => {
        // this.resultData.user_practice_unit.push({
        //     content: this.userPractice,
        //     id: res.data.puid,

        // })
        this.userPractice = ''
        this.init()
      })
    },
    clickDelAudio(item, index, pindex) {
      this.del_item = item
      this.del_index = index
      this.del_pindex = pindex
      this.Delisible = true
    },
    clickDel() {
      this.resultData.user_practice_unit[this.del_pindex].content.splice(
        this.del_index,
        1
      )
      let paramsBase = {
        puid: this.del_item.id
      }
      apiUserAiduoDel(paramsBase).then((res) => {
        //this.resultData.user_practice_unit.splice(this.del_index, 1)
      })
      this.Delisible = false
    },
    clickAgainExercise(id) {
      sessionStorage.setItem('tcl_ucld_id', id)
      let paramsBase = {
        ucld_id: id,
        lang: this.$i18n.locale
      }
      apiUcld(paramsBase).then((res) => {
        this.resultData = res.data
        this.resultData.next_course = Object.values(this.resultData.next_course)
      })
    },
    againExercise() {
      this.user_practice_unit = []
      this.userPractice = ''
    },
    viewAnswer() {
      this.answerVisible = true
    },
    selectMenu(num) {
      if (num == 0) {
        this.assianStatus = 0
        this.getAssignment()
      } else if (num == 1) {
        this.assianStatus = 1
        this.getPractice()
      }
    },
    prev() {
      this.$router.go(-1)
    }
  }
}
